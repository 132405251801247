import gsap from "gsap"
import { disablePageScroll, enablePageScroll } from "scroll-lock"
import { enableScroll, disableScroll } from "./scrollStuffDesktopN"


let linesContainer = document.querySelector(".loading-lines-container")
let fragment = document.createDocumentFragment()

function createShutter() {
  console.time('test');
  const numLines = Math.floor(window.innerHeight / 30)
  console.log(numLines+"="+window.innerHeight+"/"+30)
  fragment.replaceChildren()
  for (let count = 0; count <= numLines; count++) {
    const div = document.createElement("div")
    div.className = "loading-line"
    fragment.appendChild(div)
  }
  console.timeEnd('test')
}

createShutter()

function addShutterToDom() {
  linesContainer.replaceChildren(fragment.cloneNode(true))
  console.log("shutter added")
}

window.addEventListener("DOMContentLoaded", () => {
  addShutterToDom()
  _disablePageScroll()
})



export function _disablePageScroll() {
  if (gsap.utils.toArray(".scroll-content").length === 0) {
    disablePageScroll()
  } else {
    disableScroll()
  }
}

export function _enablePageScroll() {
  if (gsap.utils.toArray(".scroll-content").length === 0) {
    enablePageScroll()
  } else {
    enableScroll()
  }
}

//export const linesContainer = document.querySelector(".loading-lines-container")
const Toggler = document.getElementById("id03")
const body = document.body
const ELMT = [body]

const tl_shutterup = gsap.timeline({ paused: true, delay: 0.5, onReverseComplete: _disablePageScroll })

// prettier-ignore
export function createTlShutterup() {
  tl_shutterup
    .set(".loading-container", { display: "block" })
    .add("startlabel")
    .fromTo(".loading-bg", 0.01, { opacity: 1 }, { opacity: 0 }, 0)
    .staggerFromTo(".loading-line", 0.8, { scaleY: 1 }, { scaleY: 0, transformOrigin: "top" }, 0.02, 0)
    .staggerFromTo(".loading-word", 0.4, { y: "101%" }, { y: "0%" }, 0.06, 0.4)
    .fromTo(".loading-path", 0.4, { opacity: 1,y: "0%" }, { opacity: 0,y: "-100%" }, 0.2)
    .staggerFromTo(".loading-line", 0.8, { scaleY: 1 }, { scaleY: 0, transformOrigin: "bottom" }, 0.02, 0)
    .staggerFromTo(".loading-word", 0.4, { y: "0%", opacity: 1 }, { y: "-101%", opacity: 0, ease: "Power2.easeIn" }, 0.02, 0)
    .fromTo(".loading-path", 0.4, { opacity: 1,y: "0%" }, { opacity: 1,y: "-100%", ease: "Power2.easeIn" }, 0)
    .set(".loading-container", { display: "none", onComplete: _enablePageScroll})
    return tl_shutterup
}


let realLinks
let modal_toggle



const loadingPath = document.querySelector(".loading-path")
// let pathName = "Home"
let x = ".Mask > span > a"


window.onbeforeunload = function () {
  window.scrollTo(0, 0)
}

export function transition(tl) {
  document.body.classList.contains("__Mobile") === false ? (x = ".Mask > span > a") : (x = ".nav_list_item > li > a")
  realLinks = gsap.utils.toArray(x)
  console.log(`before:${realLinks}${realLinks.length}`)
  const num_items = realLinks.length / 4
  modal_toggle = realLinks.splice(-1 * num_items, num_items)
  console.log(realLinks)
  realLinks.forEach((obj) => {
    obj.addEventListener("click", (event) => {
      event.preventDefault(), (loadingPath.innerHTML = obj.innerHTML), Toggler.click()
      tl.timeScale(1.2).reverse(),
        setTimeout(() => {
          location.href = obj.href
        }, 2000)
    })
  })
}


export function waitingFor(delay = 200, tries = 10, funcToCall = null) {
  let shutterup = createTlShutterup()
  setTimeout(function () {
    tries--
    if (document.body.classList.length < 2 && document.fonts.check("3.9vw in","16px brush")) {
      // we have a match, do your stuff
      console.log("Yeah, match after: " + tries + funcToCall)
      transition(shutterup), shutterup.timeScale(1.5).play(), funcToCall
    } else if (tries > 0) {
      // we are not ready, let's try again
      setTimeout(function () {
        waitingFor(delay, tries)
      }, delay)
      console.log(`Try: ${tries}`)
    } else {
      // ok... we give up, maybe inform the client now
      // or execute alternative stuff
      console.log("Sorry, no match! Try to go on anyway..")
      transition(shutterup), shutterup.play(), funcToCall
    }
  }, delay)
}

window.addEventListener("resize",createShutter,addShutterToDom)



