import { gsap } from "gsap"
// export const MenuMobile = document.getElementsByClassName("menu_mobile")


export function navMobile() {
  console.log("hey NavMobile.js here!")
  const Toggler = document.getElementById("id03")
  const ShowMenuMobile = gsap.timeline({ paused: true })

  // Timeline
  ShowMenuMobile
    .set(".menu_mobile", { autoAlpha: 1 })
    .fromTo(".menu_mobile", 1.2, { clipPath: "circle(40px at 100% -10%" }, { scale: 1.0, clipPath: "circle(100vmax at 100% 10%", ease: "elastic.out(.6, .6)" })
    .set("#animationToggle__container", { autoAlpha: 0 }, 0.125)
    .to(".menu_word", 0.3, { y: "10px" }, 0.125)
    .staggerTo(".nav_list li", 0.4, { y: "0%", ease: "ease_in_out2" }, 0.08, "-=0.7")

  Toggler.addEventListener("click", () => {
    // logic
    if (Toggler.checked && ShowMenuMobile.progress() === 0) {
      ShowMenuMobile.restart()
    } else if (!Toggler.checked && ShowMenuMobile.progress() > 0) {
      ShowMenuMobile.reverse()
    }
  })
  document.body.classList.remove("nav_loading", "particles_loading")
}
