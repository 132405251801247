import { gsap } from "gsap"
import { getDeviceType } from "./importHelper"
import { navMobile } from "./NavMobile"
let AnimationEnabled, ripples, particles
import "./loadingScreen"
import * as mf from "./myfunctions"
import * as aT from "./animationToggle"

function chooseNav() {
  if (getDeviceType() === "Desktop") {
    navDesktop()
  } else {
    navMobile()
  }
}
chooseNav()

function navDesktop() {
  function checkAnimationMode() {
    if (localStorage.getItem("noAnimation") === "enabled") { AnimationEnabled = false}
    else if (localStorage.getItem("noAnimation") === null) { AnimationEnabled = true}
    return AnimationEnabled
  }

  const conditionalImport = () => {
    if (checkAnimationMode() == true) {
      ripples = import("./ripples")
      particles = import("./particles")
    } else {
      console.log(checkAnimationMode())
      document.body.classList.remove("nav_loading", "particles_loading")
    }
  }
  conditionalImport()

  const TlShowMenu = gsap.timeline({ paused: true })
  TlShowMenu
    .set(".menu_desktop", { autoAlpha: 1 })
    .set(".Menu-list-item", { autoAlpha: 1 })
    .to(".menu_word", 0.2, { y: "100%", autoAlpha: 0 })
    .fromTo(".fun", 0.9, { scale: 0, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, ease: "back.out(2)", onComplete: () => AnimationEnabled && ripples.RipplesInited ? ripples.StartRipples : null }, 0.1)
    .to(".Menu-list-item", 0.4, { y: "0%", stagger: 0.1, ease: "ease_in_out2" }, 0.3)
    .addPause()
    .to(".Menu-list-item", { y: "110%", stagger: 0.08, ease: "slow(0.7, 0.4, false)", id: "afterPause" })
    .to(".fun", 0.7, { scale: 0, ease: "back.in(2)" }, "<0.2")
    .to(".menu_word", 0.2, { y: "0%", autoAlpha: 1 }, 1.0)
    .set(".Menu-list-item", { autoAlpha: 0 }, 2.0)
    .set(".menu_desktop", { autoAlpha: 0 })



  document.addEventListener("keydown", (e) => {
    if (e.keyCode === 27) {
      Toggler.click()
    }
  })


  const Toggler = document.getElementById("id03")

  Toggler.addEventListener("click", () => {
    (Toggler.checked && mf.round(TlShowMenu.progress()) === 0) || mf.round(TlShowMenu.progress()) === 1.0
      ? TlShowMenu.restart()
      : Toggler.checked && mf.round(TlShowMenu.progress()) <= 0.49 && !0
        ? TlShowMenu.play()
        : !Toggler.checked && mf.round(TlShowMenu.progress()) <= 0.49
          ? TlShowMenu.reverse()
          : !Toggler.checked && mf.round(TlShowMenu.progress()) >= 0.5
            ? TlShowMenu.play()
            : Toggler.checked && mf.round(TlShowMenu.progress()) <= 0.99
              ? TlShowMenu.reverse()
              : (TlShowMenu.play(), console.log(`${mf.round(TlShowMenu.progress())}nocase`))
  })

  console.log("hey NavDesktop.js here!")



  /*if localStorage.getItem("noAnimation")=== "enabled" uncheck checkbox*/
  if (localStorage.getItem("noAnimation") === "enabled") {
    Toggler.checked = false
  }

  aT.animationToggle.addEventListener("click", conditionalImport, { passive: true })
}

document.addEventListener("resize",chooseNav)

export { StartRipples, PauseRipples, UpdateRipples } from "./ripples"