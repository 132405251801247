import { disablePageScroll, enablePageScroll, clearQueueScrollLocks, getScrollState, addLockableSelector } from "scroll-lock"
import {enableScroll,disableScroll,smoothScrollBarContent} from "./scrollStuffDesktopN"
//import * as nav from "./NavDesktop"

import * as aT from "./animationToggle"
const form_container = document.getElementById("form_container")
const liquid_menu_final = document.getElementById("liquid_menu_final")


// custom eventlistener fires when css class-updates are detected (add/remove/toggle/replace)
export function ClassListListener (el) {
  const ecl = el.classList
  ;["add", "remove", "toggle", "replace"].forEach((prop) => {
    el.classList[`_${prop}`] = ecl[prop]
    el.classList[prop] = function () {
      const args = Array.from(arguments)
      this[`_${prop}`].apply(this, args)
      el.dispatchEvent(new CustomEvent("classlistupdate", { detail: { method: prop, args } }))
      return this
    }
  })
  return el
}





function setScrollState(b) {
  if (smoothScrollBarContent.length === 0) {
    if (b === 1) {
      disablePageScroll()}
    else if (b === 2) {
      null}
    else {
      clearQueueScrollLocks()
      enablePageScroll()
      console.log(getScrollState())
  }}
  else if (smoothScrollBarContent.length > 0) {
    if (b === 1) {
      disableScroll()}
    else if (b === 2) {
      null}
    else {
      enableScroll()
  }
  }

}

export let MenuIsOpen = 0
export let FormIsOpen = 0

export function ClassToggle(Array) {
  Array.forEach(obj => {
    ClassListListener(obj).addEventListener("classlistupdate", e => {
      const args = e.detail.args.join(", ")
      obj.id === "form_container" ? (FormIsOpen = obj.classList.length - 1) : (MenuIsOpen = obj.classList.length - 1)
      console.log(`M: ${MenuIsOpen} F: ${FormIsOpen}`)
      const b = FormIsOpen + MenuIsOpen
      setScrollState(b);
    })
  })
}
const ELMT = [liquid_menu_final, form_container]


function killScrollLocks(){
  setTimeout(() => {
  clearQueueScrollLocks()
  enablePageScroll()}, 200)
}

window.addEventListener("resize", killScrollLocks)

window.addEventListener("DOMContentLoaded", () => {
  ClassToggle(ELMT)
})