/* eslint-disable camelcase */
/* eslint-disable prefer-template */
import "./js/components/importHelper"
import { getDeviceType,waitingFor,NavDesktop,gsap,aT} from "./js/components/importHelper"
import * as Fog from "./js/components/cloud"
  //import(default "./js/components/fog"))
  //import({ticker, renderer, stage, view, app, myUniforms, smokeShader}, "./js/components/fog")
import "./js/components/gsap_effects_plugins"



// GSAP SETTINGS
gsap.config({ autoSleep: 60, nullTargetWarn: true })

// VARS
const form_container = document.getElementById("form_container")
const liquid_menu_final = document.getElementById("liquid_menu_final")
const elements = document.querySelectorAll(".splitted_lines")
const checkbox = document.getElementById("id03")
const mycanvas = document.getElementById("mycanvas")
//test//test
/* let arr1 = [] = smokeShader.uniformGroup.uniforms.smoke1_color_a
let arr3 = [] = smokeShader.uniformGroup.uniforms.speed
let arr2 = [0.0, 0.1, 0.0]
let arr4 = [0.00003, -0.0002]

const test = gsap.timeline({ paused: true, delay: 1.2, yoyo:true,ease:"SlowMo", repeat: -1})
test
  .to(arr1, 6, arr2)
  .to(arr3, 6, arr4)
  .to(ticker, 6.0, { speed: "1.5", ease: "back.inOut" }) */
//test//test

let test_case = localStorage.getItem("noAnimation") === null && getDeviceType() === "Desktop"

// Timelines
const tlhello = gsap.timeline({ paused: true, delay: 0.5 })

// prettier-ignore
  test_case === true ? tlhello.to(Fog.cloudFilter.uniforms, 1.5,{clouddark:0.676,ease:"power2.inOut"},0) : null
  test_case === true ? tlhello.to(Fog.cloudFilter.uniforms, 1.0,{cloudcover:-0.232,ease:"power2.inOut"},0) : null
tlhello.from("#menew", 1.0, {autoAlpha: 0,blur: 100, ease: "expo.easeOut" }, 0.5)
  .from("#hamburger__container", 0.8, {scaleX:0, transformOrigin:"center", ease: "elastic.out(1,0.75)" }, 0.5)
  .from(".menu_word", 0.6, {autoAlpha:0,top:"50px" ,ease: "expo.in" }, 0.5)
  //.from(".hamburger div", 0.6, {width:"0%", ease: "back.in" }, 1.0)
  //.from(".hamburger div:before", 0.6, {width:"0%", ease: "back.in" }, 1.0)
  //.from("header", 2.5, {autoAlpha:0, ease: "power2.easeIn" }, 0.5)
  //.to(ticker, 2.0, { speed: "0.6", ease: "back.inOut" })
  .newburn(".splitted_chars", 1.0, { x: -10 }, 0)
  .set(".splitted_subt", { transformOrigin: "center center -150px", backfaceVisibility: "visible" }, 1.0)
  .from(".subt_line__top>.subt_word>*", { yPercent: -100, stagger: 0.01, ease: "expo.inOut" }, 1.1)
  .from(".subt_line__bottom>.subt_word>*", { yPercent: -100, stagger: 0.02, ease: "expo.inOut" }, 1.1)
  getDeviceType() === "Desktop" ? tlhello.set(".subt_line", { overflow: "visible" }) : null
  //tlhello.to(ticker, 1.5, { speed: "0.7" }, "h")

// prettier-ignore
function desktopScroll() {
  //## Day Uniforms
  console.log("desktop Scroll")
  gsap.fromTo(
    ".splitted_subt",
    { rotationX: "0", autoAlpha: 1, blur: 0 },
    {
      rotationX: "300",
      blur: 10,
      stagger: 0.01,
      autoAlpha: 0,
      ease: "customOut",
      scrollTrigger: {
        trigger: ".hero-container",
        start: "bottom bottom", // the default values
        end: "bottom top",
        scrub: 1,
      }
    }
  )


  gsap.to(
    "#menew",
    {
      yPercent: 100,
      blur: 20,
      autoAlpha: 0,
      ease: "easeNone",
      immediateRender: false,
      overwrite: "auto",
      scrollTrigger: {
        trigger: ".hero-container",
        start: "bottom bottom", // the default values
        end: "center top",
        scrub: 1,
        anticipatePin: 1
      }
    }
  )

  gsap.fromTo(".text_glass", {
    scale: "0", autoAlpha: 0, rotation: 0.1, blur: 10},
    { scale: "1", autoAlpha: 1, rotation: 0, blur: 0, ease: "Sine.inOut",
    scrollTrigger: {
        trigger: ".wrangler",
        start: "center +10%", // the default values
        end: "bottom 20%",
        scrub: 0.5
      }
    }
  )

  gsap.fromTo(".text_glass> *",{
    force3D: true, rotation: 0.1 },
    { rotation: 0,
      scrollTrigger: {
        trigger: ".wrangler",
        start: "center +10%", // the default values
        end: "bottom 20%",
        scrub: 1
      }
    }
  )


  gsap.to(".div4", {
    yPercent: -320,
    ease: "Power3.in",
    scrollTrigger: {
      trigger: ".journey_section",
      start: "center bottom-=200px", // the default values
      scrub: 1,
      anticipatePin: 1,
      toggleActions: "play pause resume reset",
    }
  })

  const tlHideDesktop = gsap.timeline({
    id: "hide",
    pause: true,
    immediateRender: false,
    overwrite: "true",
    scrollTrigger: {
      trigger: ".hero-container",
      start: "bottom bottom", // the default values
      end: "bottom 10%",
      scrub: 1,
      // onUpdate: self => {console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());},
    }
  })


  tlHideDesktop
    .burnOut(".splitted_chars", { x: -2 , force3D: true, ease:"SineOut" })
    //.to("#mycanvas", 3, { opacity: 1.0, y: 0, ease: "line.easeOut", id: "a" }, 0)
    // .to(".tint", 4.0, { opacity: 0.7, y: 0, zIndex: -1, id: "k" }, 1)
      //.to(ticker, 1.5, { speed: "2.0", ease: "customInOut", id: "r" }, 0.2)
    // .to(".tint",3, {gradient: "linear-gradient(161deg, rgba(255, 33, 33, 1) 0%, rgba(2, 63, 99, 1) 100%)"})
    // .to(".wrapper",1,{autoAlpha:0},)
    //.set("#mycanvas", { zIndex: "0" }, "<")
    //.fromTo(".text_glass", 1.5, { scale: "0", autoAlpha: 0, rotation: 0.1, blur: 10}, { scale: "1", autoAlpha: 1, rotation: 0, blur: 0, ease: "power1.inOut" },0.5)
    //.fromTo(".text_glass> *", 1.5, { force3D: true, rotation: 0.1 }, { rotation: 0 , id:"text_glass"}, "<")
    // .from('#menew', 3, {blur: 100, y: 0, autoAlpha: 0,id:"c"}, "-=3")
    //.to("#mycanvas", 5, { opacity: 0.7, id: "f" }, 6)

    function addShaderAnimation() {
      if (test_case === false) {
        return
      } else {
        let daySky1  = Fog.cloudFilter.uniforms.skycolor1
        let daySky2  = Fog.cloudFilter.uniforms.skycolor2
        let dayCloudDark  = Fog.cloudFilter.uniforms.clouddark
        let dayCloudLight  = Fog.cloudFilter.uniforms.cloudlight
        let dayCC  = Fog.cloudFilter.uniforms.cc
        let daySkyTint  = Fog.cloudFilter.uniforms.skytint

        let eveSky1  = [0.021,0.018,0.050]
        let eveSky2  = [0.068,0.128,0.250]
        let eveCloudDark  = [0.644]
        let eveCloudLight  = [0.460]
        let eveCC  = [0.633,0.685,0.665]
        let eveSkyTint  = [0.028]

      let tlHideDesktopAnimationOn = gsap.timeline({
        paused: true,
        ease:"power2.inOut",
        scrollTrigger: {
          trigger: "main",
          start: "top center", // the default values
          end: "bottom bottom",
          scrub: 1,
        }
      })
        tlHideDesktopAnimationOn.to(daySky1, 3, eveSky1,3.0)
          .to(daySky2, 3, eveSky2,3.0)
          .to(Fog.cloudFilter.uniforms, 3.0,{cloudlight:0.46},3.0)
          .to(Fog.cloudFilter.uniforms, 3.0,{clouddark:0.644},3.0)
          .to(dayCC, 3.0, eveCC,3.0)
          .to(Fog.cloudFilter.uniforms, 3.0,{skytint:-0.58},3.0)

  }}
  addShaderAnimation()
  aT.animationToggle.addEventListener("click", addShaderAnimation, { passive: true })
}
// prettier-ignore
function mobileScroll() {
  const isTouch = 'ontouchstart' in document.documentElement
  //ticker ? gsap.set(ticker, { speed: "0.2" }) : null
  //gsap.set("#mycanvas", { opacity: "1" })
  gsap.set(".header_box", { position: "fixed" })
  gsap.set(".subt", { overflow: "hidden" })
  console.log("mobile Scroll")
  gsap.to(
    ".subt_line__top",
    {
      yPercent: -110,
      autoAlpha:0,
      ease :"expoInOut",
      immediateRender: false,
      scrollTrigger: {
        trigger: ".wrapper",
        start: "bottom bottom", // the default values
        end: "bottom top",
        scrub: 0.75
      }
    }
  )

  gsap.to(
    ".subt_line__bottom",
    {
      yPercent: 110,
      autoAlpha:0,
      ease :"expoInOut",
      immediateRender: false,
      scrollTrigger: {
        trigger: ".wrapper",
        start: "bottom bottom", // the default values
        end: "bottom top",
        scrub: 0.75
      }
    }
  )

  gsap.to("#menew",
    {
    yPercent: 100,
    ease: "easeNone",
    immediateRender: false,
    overwrite: "true",
    scrollTrigger: {
      trigger: ".wrangler",
      start: "bottom bottom", // the default values
      end: "bottom top",
      scrub: 0.75,
      pin: true,
      pinSpacing: "none",
      anticipatePin: 1,
      markers: false

    }
  })

  gsap.to(
    ".splitted_words",
    {
      yPercent: 100,
      autoAlpha:0,
      ease :"expoInOut",
      scrollTrigger: {
        trigger: ".wrapper",
        start: "bottom bottom", // the default values
        end: "bottom top",
        scrub: 0.75
      }
    }
  )

  const tlHideMobile = gsap.timeline({
    id: "hide",
    pause: true,
    scrollTrigger: {
      trigger: "main",
      start: "top top",
      end: "bottom bottom",
      scrub: 0.75
      // onUpdate: self => {console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());},
    }
  })

  tlHideMobile
      //.set("#mycanvas", { zIndex: "0" }, "<")
      //.to("#mycanvas", 0.3, { opacity: 1.0, y: 0, ease: "customInOutout", id: "a" }, 0)
      //.to(".tint", 2.0, { opacity: 0.4, y: 0, zIndex: -1, id: "k" }, 1)
      //.to(ticker, 1.5, { speed: "1.3", ease: "customInOut", id: "r" }, 0.5)
    // .to(".wrapper",1,{autoAlpha:0},)
      //.set("#mycanvas", { zIndex: "0" }, "<")
    .fromTo(".text_glass", 3, { scale: "1", autoAlpha: 0, rotation: 0.0 }, { scale: "1", autoAlpha: 1, rotation: 0.001, force3D:true, ease: "power2.inOut" }, "-=3.0")
    // .fromTo(".text_glass> *",3,{force3D:false, rotation:0.1}, {rotation:0},"-=3")
    // .from('#menew', 3, {blur: 100, y: 0, autoAlpha: 0,id:"c"}, "-=3")
    // .to("#mycanvas", 5,{opacity:0.5,zIndex:"0",id:"f"}, 2)
      //.to(ticker, 1.5, { speed: "0.7" })
}



/* function pause_pixi() {
  localStorage.getItem("noAnimation") !== "null" ? ticker.stop() : ticker.start()
  console.log("pause_pix")
} */





 /*  aT.animationToggle.checked = !aT.animationToggle.checked */
/*   aT.noAnimation !== "enabled" ? localStorage.setItem("noAnimation", "enabled") : localStorage.setItem("noAnimation", "null")
  aT.animationToggleFunction(ticker) */
window.addEventListener("load", () => {
  localStorage.getItem("noAnimation") === "enabled" ? mycanvas.classList.add("staticCloudBg") : gsap.set("#mycanvas", { opacity: 1.0})
  // initPageTransitions()
  //gsap.set("#mycanvas", { opacity: 0.7})
  getDeviceType() === "Desktop" ? desktopScroll() : mobileScroll()
    //test.play()
  //tlhello.play()
  console.log(Fog.cloudFilter)
  waitingFor(500, 5,tlhello.timeScale(0.75).play())
})





/* aT.animationToggle.addEventListener("click", () =>
  aT.animationToggleFunction(ticker)
})
if (noAnimation === null) {
  let animationToggle.checked = false
  animationToggle.checked = !animationToggle.checked
} */

