export const cloudShader =/* glsl */`
uniform float speed;
uniform float time;
uniform vec2 dimensions;
const float cloudscale = 2.4;
//const float cloudcover = -0.232;
const float cloudalpha = 8.0;

//###### UNIFORMS #####
uniform float cloudcover;
uniform float clouddark;
uniform float cloudlight;
uniform vec3 skycolor1;
uniform vec3 skycolor2;
uniform vec3 cc;
uniform float skytint;

const mat2 m = mat2( 1.6,  1.2, -1.2,  1.6 );

vec2 hash( vec2 p ) {
	p = vec2(dot(p,vec2(127.1,311.7)), dot(p,vec2(269.5,183.3)));
	return -1.0 + 2.0*fract(sin(p)*43758.5453123);
}

float noise( in vec2 p ) {
    const float K1 = 0.366025404; // (sqrt(3)-1)/2;
    const float K2 = 0.211324865; // (3-sqrt(3))/6;
		vec2 i = floor(p + (p.x+p.y)*K1);
    vec2 a = p - i + (i.x+i.y)*K2;
    vec2 o = (a.x>a.y) ? vec2(1.0,0.0) : vec2(0.0,1.0); //vec2 of = 0.5 + 0.5*vec2(sign(a.x-a.y), sign(a.y-a.x));
    vec2 b = a - o + K2;
		vec2 c = a - 1.0 + 2.0*K2;
    vec3 h = max(0.5-vec3(dot(a,a), dot(b,b), dot(c,c) ), 0.0 );
		vec3 n = h*h*h*h*vec3( dot(a,hash(i+0.0)), dot(b,hash(i+o)), dot(c,hash(i+1.0)));
    return dot(n, vec3(70.0));
}

float fbm(vec2 n) {
	float total = 0.0, amplitude = 0.1;
	for (int i = 0; i < 6; i++) {
		total += noise(n) * amplitude;
		n = m * n;
		amplitude *= 0.4;
	}
	return total;
}

// -----------------------------------------------

void mainImage( out vec4 fragColor, in vec2 fragCoord ) {
    vec2 p = fragCoord.xy / dimensions.xy;
		vec2 uv = p*vec2(dimensions.x/dimensions.y,1.0);
    float zeit = time * speed;
    float q = fbm(uv * cloudscale * 0.5);


    //ridged noise shape
	float r = 0.0;
		uv *= cloudscale;
    uv -= q - zeit;
    float weight = 0.8;
    for (int i=0; i<8; i++){
		r += abs(weight*noise( uv ));
        uv = m*uv + zeit;
		weight *= 0.7;
    }

    //noise shape
	float f = 0.0;
    uv = p*vec2(dimensions.x/dimensions.y,1.0);
		uv *= cloudscale;
    uv -= q - zeit;
    weight = 0.7;
    for (int i=0; i<8; i++){
		f += weight*noise( uv );
        uv = m*uv + zeit;
		weight *= 0.6;
    }

    f *= r + f;

    //noise color
    float c = 0.0;
    zeit = time * speed * 2.0;
    uv = p*vec2(dimensions.x/dimensions.y,1.0);
		uv *= cloudscale*2.0;
    uv -= q - zeit;
    weight = 0.4;
    for (int i=0; i<7; i++){
		c += weight*noise( uv );
        uv = m*uv + zeit;
		weight *= 0.6;
    }

    //noise ridgecolor
    float c1 = 0.0;
    zeit = time * speed * 3.0;
    uv = p*vec2(dimensions.x/dimensions.y,1.0);
		uv *= cloudscale*3.0;
    uv -= q - zeit;
    weight = 0.4;
    for (int i=0; i<7; i++){
		c1 += abs(weight*noise( uv ));
        uv = m*uv + zeit;
		weight *= 0.6;
    }

    c += c1;

    vec3 skycolor = mix(skycolor2, skycolor1, p.y);
    vec3 cloudcolor = cc * clamp((clouddark + cloudlight*c), 0.008, 1.456);

    f = cloudcover + cloudalpha*f*r;

    vec3 result = mix(skycolor, clamp(skytint * skycolor + cloudcolor, 0.0, 1.0), clamp(f + c, 0.0, 1.632));

	fragColor = vec4( result, 1.0 );
}
void main() {
    mainImage(gl_FragColor,gl_FragCoord.xy);
}`
