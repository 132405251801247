import * as PIXI from "pixi.js"
import * as utils from "@pixi/utils"
import { getDeviceType} from "./importHelper"
//import { realFrag,oldFrag,testFrag, newFrag, frag, prodFrag, laserFrag } from "./fog_shader"
import {cloudShader} from "./shaders/cloudShader"
import gsap from "gsap"
import * as observer from "./EventClasslistUpdate"
import * as aT from "./animationToggle"

const fragmentShader = cloudShader
const vertShader = undefined
const form_container = document.getElementById("form_container")
const liquid_menu_final = document.getElementById("liquid_menu_final")
const ELMT = [liquid_menu_final, form_container]

// PIXI VARS
export let CloudInited, CloudRunning, CloudPaused, CloudDestroyed, Cloudready = false
const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
//const width = window.innerWidth
//const height = window.innerHeight
const pRatio = [width / 100, height / 100]
const mycanvas = document.getElementById("mycanvas")
const html_container = document.querySelector(".wrangler")
let res = 70
if (getDeviceType() === "Mobile") {res = 30} // for better performance on mobile, resolution is decreased


let app
export let cloudFilter, cloudApp

let skyColor1_ = [0.234,0.443,0.605]
let skyColor2_ = [0.555,0.288,0.299]
let cc_ = [0.645,0.470,0.336]
let clouddark_ = [1.468]//[0.676]
let cloudlight_ =  [0.756]
let skytint_  = [0.66]
let cloudcover_ = [5.83]

let appOptions = {
  width: pRatio[0],
  height: pRatio[1],
  resolution: res,
  backgroundAlpha: 1.0,
  useContextAlpha: true,
  transparent: true,
  view: mycanvas,
  clearBeforeRender: true,
}
let cloudUniforms = {
  dimensions: [width, height],
  time: 0,
  speed: -0.05,
  clouddark: clouddark_,
  cloudlight: cloudlight_,
  skycolor1: skyColor1_,
  skycolor2: skyColor2_,
  cc: cc_,
  skytint: skytint_,
  cloudcover: cloudcover_
}






class AppCreator {
  constructor(appOptions ) {
    this.appOptions = appOptions
    this.app = new PIXI.Application(this.appOptions)
    this.stage = this.app.stage
    this.view = this.app.view
    this.ticker = this.app.ticker
    this.renderer = this.app.renderer
    this.mainContainer = new PIXI.Container()
    this.emptyTexture = new PIXI.Sprite(PIXI.Texture.EMPTY)
    this.emptyTexture.width = this.renderer.width
    this.emptyTexture.height = this.renderer.height
    this.mainContainer.addChild(this.emptyTexture)
    this.app.stage.addChild(this.mainContainer)
    this.app.stop()
  }
  returnApp() {
    return this.app
  }
  returnContainer() {
    return this.mainContainer 
  }
  returnTexture() {
    return this.emptyTexture 
  }
}


class FilterCreator {
  constructor(app,emptyTexture,vertShader,fragShader, uniforms,) {
    this.app = app
    this.vertShader = vertShader
    this.fragShader = fragShader
    this.uniforms = uniforms
    this.emptyTexture = emptyTexture
    this.filter = new PIXI.Filter(this.vertShader,this.fragShader, this.uniforms)
  }
  initAnimation() {
    this.emptyTexture.filters = [this.filter]
    this.emptyTexture.blendMode = PIXI.BLEND_MODES.ADD
    this.app.start()
    this.app.ticker.add((delta) => {
      this.filter.uniforms.time += 0.008 * delta
    })
    CloudInited = true
    document.body.classList.remove("pixi_loading")
    console.log("inited Cloud")
  }
  pauseAnimation() {
    this.app.ticker.stop()
    CloudInited = true
    CloudPaused = true
    CloudDestroyed = false
    CloudRunning = false
    console.log(`CloudInited: ${CloudInited},`,`CloudRunning: ${CloudRunning},`,`paused: ${CloudPaused},`, `destroyed: ${CloudDestroyed}`)
    console.log("pauseCloud")
  }
  startAnimation() {
    this.app.ticker.start()
    CloudInited = true
    CloudPaused = false
    CloudDestroyed = false
    CloudRunning = true
    console.log(`CloudInited: ${CloudInited},`,`CloudRunning: ${CloudRunning},`,`paused: ${CloudPaused},`, `destroyed: ${CloudDestroyed}`)  
  }
  destroyAnimation() {
    this.app.destroy(true)
    CloudInited = false
    CloudPaused = false
    CloudDestroyed = true
    CloudRunning = false
    console.log(`CloudInited: ${CloudInited},`,`CloudRunning: ${CloudRunning},`,`paused: ${CloudPaused},`, `destroyed: ${CloudDestroyed}`)
    console.log("destroyCloud")
  }
  resizeAnimation() {
    console.log("window resized")
    cloudApp.returnApp().renderer.resize(document.querySelector(".wrangler").clientWidth / 100, document.querySelector(".wrangler").clientHeight / 100)
    cloudApp.returnApp().resize(mycanvas)
    cloudFilter.uniforms.dimensions = [document.querySelector(".wrangler").clientWidth, document.querySelector(".wrangler").clientHeight]    
  }
}

if (localStorage.getItem("noAnimation") === null && getDeviceType() === "Desktop") {
  cloudApp = new AppCreator(appOptions);
  cloudFilter = new FilterCreator(cloudApp.returnApp(), cloudApp.returnTexture(),vertShader, cloudShader, cloudUniforms);
  cloudFilter.initAnimation();
  window.addEventListener("resize", cloudFilter.resizeAnimation, { passive: true });
} else {
  document.body.classList.remove("pixi_loading");
}













function checkMenuState() {
  setTimeout(function () {
    const b = observer.FormIsOpen + observer.MenuIsOpen
    console.log(`Cloud: M: ${observer.MenuIsOpen} F: ${observer.FormIsOpen}`)
    console.log(b)
    if (b === 1) {
      cloudFilter.pauseAnimation()
      return
    } else if (b === 2) {
      return
    } else if (b === 0) {
      cloudFilter.startAnimation()
      return
    }
  }, 500)
}

function activateEvents(){
if (localStorage.getItem("noAnimation") === null) {
  ELMT.forEach((elem) => {
    elem.addEventListener("classlistupdate", checkMenuState, { passive: true })
})}}
getDeviceType() === "Desktop" ? activateEvents() : null

aT.animationToggle.addEventListener("click", animationModeHandler, { passive: true })

function animationModeHandler() {
  setTimeout(function () {
    if ((aT.animationToggle.checked) && (!CloudInited)) {
      activateEvents()
      cloudFilter.initAnimation()
      gsap.to(cloudFilter.uniforms, 1.5,{clouddark:0.676,ease:"power2.Out"},0)
      gsap.to(cloudFilter.uniforms, 1.0,{cloudcover:-0.232,ease:"power2.Out"},0)
      return
    } else if ((!aT.animationToggle.checked) && (CloudRunning || CloudPaused)) {
      cloudFilter.destroy()
      deactivateEvents()
      //mycanvas.style.backgroundImage = "url('noanimation.f83f7e1f.webp')"
      return
    } else if ((!aT.animationToggle.checked) && (!CloudInited)) {
      return
    }
  },200)
}

function deactivateEvents(){
      liquid_menu_final.removeEventListener("classlistupdate", checkMenuState)
      form_container.removeEventListener("classlistupdate", checkMenuState)
}

//export default {ticker, renderer, stage, view, app}


//## Day Uniforms/
/*
let daySky1  = cloudFilter.uniforms.skycolor1
let daySky2  = cloudFilter.uniforms.skycolor2
let dayCloudDark  = cloudFilter.uniforms.clouddark
let dayCloudLight  = cloudFilter.uniforms.cloudlight
let dayCC  = cloudFilter.uniforms.cc
let daySkyTint  = cloudFilter.uniforms.skytint

//## Night Uniforms
/* let eveSky1  = [0.22,0.3,0.35]
//let eveSky2  = [0.63,0.5,0.3]
//let eveCloudDark  = [0.516]
//let eveCloudLight  = [0.612]
//let eveCC  = [0.7,0.56,0.24]


let eveSky1  = [0.06, 0.05, 0.14]
let eveSky2  = [0.1, 0.19, 0.37]
let eveCloudDark  = [0.644]
let eveCloudLight  = [0.460]
let eveCC  = [0.592,0.578,0.630]
let eveSkyTint  = [0.028]

let test = gsap.timeline({ paused: true, delay: 1.2, yoyo:true,ease:"SineIn", repeat: -1})
test
  .to(daySky1, 6, eveSky1,0)
  .to(daySky2, 6, eveSky2,0)
  .to(dayCloudDark, 6, eveCloudDark,0)
  .to(dayCloudLight, 6.0, eveCloudLight,0)
  .to(dayCC, 6.0, eveCC,0)
  .to(daySkyTint, 6.0, eveSkyTint,0)
//test//test

//test.play()
*/






