export let noAnimation = localStorage.getItem("noAnimation")
export let animationToggle =document.getElementById("id04")



function checkMode() {
  if (!animationToggle.checked) {
    enableNoAnimationMode()
  } else {
    disableNoAnimationMode()
  }
}

/* if (noAnimation == null) {
  animationToggle.checked = true
} */

export function enableNoAnimationMode() {
  document.body.classList.add("noAnimation")
  localStorage.setItem("noAnimation", "enabled")
}

export function disableNoAnimationMode() {
  document.body.classList.remove("noAnimation")
  localStorage.clear()
}

export function initStorageCheck() {
  if (localStorage.getItem("noAnimation") !== "enabled") {
    animationToggle.checked = true;
    return false;
  }
  if (localStorage.getItem("noAnimation") === "enabled") {
    animationToggle.checked = false;
    return true;
  }
}

window.onload = function() {
  animationToggle.addEventListener("click", checkMode)
  initStorageCheck()
}



/* animationToggleFunction(ticker) */

/* animationToggle.addEventListener("click", () => {
  noAnimation = localStorage.getItem("noAnimation")
  if (noAnimation !== "enabled") {
    enableNoAnimationMode()
} else {
  disableNoAnimationMode()
  }
});
 */
