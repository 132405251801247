import { isMobile } from "@pixi/utils"


export function getDeviceType(){
  if (isMobile.phone || isMobile.tablet){
    return "Mobile" }
  else {
    return "Desktop"}
}

let  DeviceType = getDeviceType()

if (getDeviceType() === "Desktop") {
  document.body.classList.add("__Desktop")
} else {
  document.body.classList.add("__Mobile")
}

import "./NavDesktop"
import "./scrollStuffDesktopN"

//DeviceType === "Desktop" ? (NavDesktop = import("./NavDesktop"), ScrollDesktop = import("./scrollStuffDesktopN")) : (NavMobile = import("./NavMobile"), ScrollMobile = import("./scrollStuffDesktopN"))
import {gsap} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {CSSRulePlugin} from "gsap/CSSRulePlugin"
gsap.registerPlugin(ScrollTrigger)
/* import { CustomEase } from "gsap/CustomEase"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
gsap.registerPlugin(ScrollTrigger, CustomEase,DrawSVGPlugin) */
export {gsap} from "gsap"
export { ScrollTrigger } from "gsap/ScrollTrigger"
export { CustomEase } from "gsap/CustomEase"
export { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
export { CSSRulePlugin } from "gsap/CSSRulePlugin"


import * as loadingScreen from "./loadingScreen"
export * as form from "./contactForm_old"
export * as aT from "./animationToggle"
export * as ClassObserver from "./EventClasslistUpdate"


export { disablePageScroll, enablePageScroll, getScrollState, clearQueueScrollLocks } from "scroll-lock"
export { waitingFor } from "./loadingScreen"
