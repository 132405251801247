import $ from "jquery"
import "../jquery.ripples.min.js"
import * as observer from "./EventClasslistUpdate"
import * as aT from "./animationToggle"
import { getDeviceType } from "./importHelper"
let RipplesInited, RipplesStarted, RipplesPaused, RipplesDestroyed = false
const form_container = document.getElementById("form_container")
const liquid_menu_final = document.getElementById("liquid_menu_final")
const ELMT = [liquid_menu_final, form_container]

function InitRipples() {
  // init ripples plugin and sets it to pause as menu is not visible yet
  $("#id01").ripples({ resolution: 512, perturbance: 0.04, imageUrl: "./assets/5.webp" })
  RipplesInited = true
  RipplesDestroyed = false
  RipplesStarted = false
  RipplesPaused = false
  console.log(`ripple initialized!${RipplesInited}`)
  $("#id01").ripples("pause")
  setTimeout(() => {
  document.body.classList.remove("nav_loading")}, 1000)
}
localStorage.getItem("noAnimation") === null && getDeviceType() === "Desktop" ? InitRipples() : document.body.classList.remove("nav_loading")


function PauseRipples() {
  setTimeout(() => {
  $("#id01").ripples("pause")},1000)
  RipplesInited = true
  RipplesPaused = true
  RipplesDestroyed = false
  RipplesStarted = false

  console.log(`ripple paused!${RipplesPaused}`)
}

function StartRipples() {
  $("#id01").ripples("play")
  RipplesInited = true
  RipplesStarted = true
  RipplesDestroyed = false
  RipplesPaused = false
  console.log(`ripple starded!${RipplesStarted}`)
}

function UpdateRipples() {
  $("#id01").ripples("updateSize")
  console.log("ripple updated!")
}

function DestroyRipples() {
  RipplesInited ? $("#id01").ripples("destroy") : null
  RipplesInited = false
  RipplesDestroyed = true
  RipplesStarted = false
  RipplesPaused = false
  console.log(`ripple destroyed!${RipplesDestroyed}`)
}

window.addEventListener("resize", UpdateRipples)

function checkMenuState() {
  setTimeout(() => {
  console.log(`ripples: M: ${observer.MenuIsOpen} F: ${observer.FormIsOpen}`)
  if (observer.MenuIsOpen === 1 && observer.FormIsOpen === 0) {
    StartRipples()}
  if (observer.MenuIsOpen === 0 && observer.FormIsOpen === 0) {
    PauseRipples()}
  if (observer.MenuIsOpen === 1 && observer.FormIsOpen === 1) {
    PauseRipples()}
  },100)
}

function activateEvents(){
if (localStorage.getItem("noAnimation") === null) {
  ELMT.forEach((elem) => {
    elem.addEventListener("classlistupdate", checkMenuState, { passive: true })
})}}

getDeviceType() === "Desktop" ? activateEvents() : null

aT.animationToggle.addEventListener("click", animationModeHandler, { passive: true })

function animationModeHandler() {
  if ((!aT.animationToggle.checked) && (RipplesInited)) {
    DestroyRipples()
    deactivateEvents()
  } else {
    activateEvents()
    InitRipples()
  }
}

function deactivateEvents(){
      liquid_menu_final.removeEventListener("classlistupdate", checkMenuState)
      form_container.removeEventListener("classlistupdate", checkMenuState)
}

console.log("hey ripples.js here!")
export {InitRipples, PauseRipples, StartRipples, UpdateRipples, DestroyRipples, RipplesInited, RipplesStarted, RipplesPaused, RipplesDestroyed}