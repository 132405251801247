import gsap from "gsap"
import { CustomEase } from "gsap/CustomEase"



function getRandomFrom(array) {
    const selected = array.selected
    while (selected === (array.selected = Math.floor(Math.random() * array.length))) {}
    return array[array.selected]
  }
let rand_array = [170,192,163, 222,181,250,166,178,159,144,198,155,128]

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

let roughtEase = "rough ({ template: none.out, strength: 1, points: 50, taper: 'none', randomize: true, clamp: true })"
gsap.registerPlugin(CustomEase)

CustomEase.create("customInOut", "M0,0 C0.5,0 0.1,1 1,1"),
CustomEase.create("customOut", "M0,0c0.2,0.6,0.1,1,1,1"),
CustomEase.create("ease_in_out", ".76,0,.32,.99"),
CustomEase.create("ease_in_out2", ".46,.06,.56,.9"),
CustomEase.create("customInOut", "M0,0 C0.126,0.382 0.282,0.674 0.44,0.822 0.632,1.002 0.818,1.001 1,1 "),
CustomEase.create("slideIn", "M0,0 C0.336,0.134 0.386,0.292 0.506,0.45 0.671,0.667 0.723,1.2 1,1 "),
CustomEase.create("sticky","M0,0 C0.106,0.165 0.462,0.186 0.542,0.3 0.634,0.432 0.642,0.411 0.69,0.496 0.708,0.528 0.764,0.624 0.774,0.646 0.806,0.718 0.78,1 1,1 "
  ),
CustomEase.create("fly","M0,0 C0,0 0.046,0.315 0.085,0.518 0.113,0.668 0.13,0.751 0.17,0.896 0.198,1.002 0.221,1.063 0.26,1.164 0.274,1.203 0.288,1.226 0.31,1.261 0.326,1.288 0.339,1.305 0.36,1.327 0.373,1.342 0.385,1.351 0.402,1.361 0.417,1.369 0.43,1.391 0.447,1.394 0.463,1.396 0.484,1.403 0.5,1.4 0.519,1.395 0.59,1.394 0.61,1.388 0.69,1.36 0.668,1.387 0.698,1.352 0.782,1.25 0.78,1.137 0.87,1.056 0.891,1.036 0.911,1.025 0.937,1.014 0.958,1.005 1,1 1,1 "
  )
CustomEase.create("smoothIn", "M0,0,C0.197,0,0.485,0.059,0.636,0.216,0.782,0.369,0.836,0.55,0.888,0.734,0.918,0.843,0.966,0.942,1,1")
gsap.registerEffect({
  name: "burnIn",
  extendTimeline: true,
  defaults: {
    y: 0,
    x: 0,
    duration: 1,
    ease: "none",
  },
  effect: (targets, config) => {
    gsap.set(targets, { filter: "blur(0px) brightness(1)" })
    const tl = gsap.timeline()
    tl.from(targets, {
      filter: "blur(20px) brightness(1)",
      scale: 0.3,
      rotation: -10,
      duration: config.duration,
      ease: config.ease,
      x: config.x,
      y: config.y,
      stagger: {
        each: 0.1,
        ease: "none",
      },
    })
    tl.fromTo(
      targets,
      { color: "#fff", scale: 0.3, blur: 30, duration: 0.2, autoAlpha: 0, ease: "none" },
      { color: "#bacb83", scale: 1, autoAlpha: 1, blur: 0, stagger: { each: 0.1, ease: "none" } },
      1
    )
    return tl
  },
})

gsap.registerEffect({
  name: "burnOut",
  extendTimeline: true,
  defaults: {
    y: 0,
    x: 0,
    duration: 1,
    ease: "none",
  },
  effect: (targets, config) => {
    gsap.set(targets, { filter: "blur(0px) brightness(1)" })
    const tl = gsap.timeline()
    tl.to(targets, {
      filter: "blur(20px) brightness(10)",
      scale: 2,
      rotationZ: 120,
      rotationX: 60,
      duration: config.duration,
      ease: config.ease,
      x: 300,
      y: -300,
      //clearProps: "filter",
      stagger: {
        each: 0.04,
        from:"center",
        y: getRandomArbitrary(-120,-350),
        autoAlpha: 0,
        ease: "SineOut",
      },
    })

    tl.to(targets, { duration: 1, autoAlpha: 0, ease: "SineOut", stagger: { each: 0.1, ease: "power2" } }, 0)
    return tl
  },
})

gsap.registerEffect({
  name: "burn",
  extendTimeline: true,
  defaults: {
    y: 0,
    x: 0,
    duration: 1,
    ease: "none",
  },
  effect: (targets, config) => {
    gsap.set(targets, { filter: "blur(0px) brightness(1)", autoAlpha: 0 })
    const tl = gsap.timeline()
    tl.to(targets, {
      autoAlpha: 1,
      filter: "blur(30px) brightness(10)",
      scale: 0.3,
      rotation: 10,
      duration: config.duration,
      ease: config.ease,
      x: config.x,
      y: config.y,
      clearProps: "filter,autoAlpha",
      stagger: {
        each: 0.1,
        autoAlpha: 0,
        ease: "none",
      },
    })

    tl.to(targets, { duration: 1, autoAlpha: 0, ease: "none", stagger: { each: 0.1, ease: "power2" } }, 0)

    return tl.reversed(true)
  },
})

gsap.registerEffect({
  name: "newburn",
  extendTimeline: true,
  defaults: {
    y: 0,
    x: 0,
    duration: 0.5,
    ease: "none",
  },
  effect: (targets, config) => {
    gsap.set(targets, { filter: "blur(0px) brightness(1)" })
    const tl = gsap.timeline()
    tl.from(targets, {
      filter: "blur(20px) brightness(8)",
      scale: 0.8,
      rotation: -10,
      duration: config.duration,
      ease: config.ease,
      x: config.x,
      y: config.y,
      stagger: {
        each: 0.02,
        ease: "none",
      },
    })
    tl.from(
      targets,
      {
        duration: 0.1,
        opacity: 0,
        ease: "none",
        stagger: {
          each: 0.02,
          ease: "power2",
        },
      },
      0
    )
    return tl
  },
})

// marquee-effect
// Once that effect is registered, all you have to do is:

// let tl = gsap.effects.ticker(".ticker", {
// speed: 100,
// className: "ticker-content",
// direction: "right"
// });
gsap.registerEffect({
  name: "ticker",
  effect(targets, config) {
    const master = gsap.timeline()
    buildTickers(targets, config)
    function clone(el) {
      const clone = el.cloneNode(true)
      el.parentNode.insertBefore(clone, el)
      clone.style.position = "absolute"
      return clone
    }
    function nestChildren(el, className) {
      const div = document.createElement("div")
      while (el.firstChild) {
        div.appendChild(el.firstChild)
      }
      el.appendChild(div)
      className && div.setAttribute("class", className)
      div.style.display = "inline-block"
      div.style.boxSizing = "border-box"
      return div
    }
    function buildTickers(targets, config, isResize) {
      if (isResize) {
        // on window resizes, we should delete the old clones and reset the widths
        targets.clones.forEach((el) => el && el.parentNode && el.parentNode.removeChild(el))
        gsap.set(targets.chunks, { x: 0 })
      } else {
        targets.clones = []
        targets.chunks = []
      }
      master.clear()
      const { clones } = targets
      const { chunks } = targets
      targets.forEach((el, index) => {
        const chunk = chunks[index] || (chunks[index] = nestChildren(el, config.className))
        const chunkWidth = chunk.offsetWidth + (config.padding || 0)
        const cloneCount = Math.ceil(el.offsetWidth / chunkWidth)
        const chunkBounds = chunk.getBoundingClientRect()
        const elBounds = el.getBoundingClientRect()
        const right = (el.dataset.direction || config.direction) === "right"
        const tl = gsap.timeline()
        const speed = parseFloat(el.dataset.speed) || config.speed || 100
        let i
        let offsetX
        let offsetY
        let bounds
        let cloneChunk
        let all
        el.style.overflow = "hidden"
        gsap.getProperty(el, "position") !== "absolute" && (el.style.position = "relative") // avoid scrollbars
        for (i = 0; i < cloneCount; i++) {
          cloneChunk = clones[i] = clone(chunk)
          if (!i) {
            bounds = cloneChunk.getBoundingClientRect()
            offsetX = bounds.left - chunkBounds.left
            offsetY = bounds.top - chunkBounds.top
          }
          gsap.set(cloneChunk, { x: offsetX + (right ? -chunkWidth : chunkWidth) * (i + 1), y: offsetY })
        }
        all = clones.slice(0)
        all.unshift(chunk)
        tl.fromTo(
          all,
          {
            x: right ? `-=${chunkBounds.right - elBounds.left}` : `+=${elBounds.right - chunkBounds.left}`,
          },
          {
            x: (right ? "+=" : "-=") + elBounds.width,
            ease: "none",
            duration: elBounds.width / speed,
            overwrite: "auto",
          }
        ).to(all, {
          x: (right ? "+=" : "-=") + chunkWidth,
          ease: "none",
          duration: chunkWidth / speed,
          repeat: -1,
        })
        master.add(tl, 0)
      })
      // rerun on window resizes, otherwise there could be gaps if the user makes the window bigger.
      isResize || window.addEventListener("resize", () => buildTickers(targets, config, true))
    }
    return master
  },
})

// GSAP linear-gradient-Plugin
// assumes you'll always define things with a "deg" value, and it'll get applied to backgroundImage
gsap.registerPlugin({
  name: "gradient",
  init(target, value) {
    const forceDeg = (value) => (~value.indexOf("deg") ? value : (value = value.split("(")) && `${value.shift()}(180deg, ${value.join("(")}`)
    this.add(target.style, "background", forceDeg(`${window.getComputedStyle(target).background}`), forceDeg(value))
  },
})
/*  USAGE:
gsap.to(".tint",3, {gradient: "linear-gradient(161deg, rgba(255, 33, 33, 1) 0%, rgba(2, 63, 99, 1) 100%)"}) */
// gsap blur plugin
;(function () {
  const blurProperty = gsap.utils.checkPrefix("filter")
  const blurExp = /blur\((.+)?px\)/
  const getBlurMatch = (target) => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || []

  gsap.registerPlugin({
    name: "blur",
    get(target) {
      return +getBlurMatch(target)[1] || 0
    },
    init(target, endValue) {
      const data = this
      let filter = gsap.getProperty(target, blurProperty)
      const endBlur = `blur(${endValue}px)`
      const match = getBlurMatch(target)[0]
      let index
      if (filter === "none") {
        filter = ""
      }
      if (match) {
        index = filter.indexOf(match)
        endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length)
      } else {
        endValue = filter + endBlur
        filter += filter ? " blur(0px)" : "blur(0px)"
      }
      data.target = target
      data.interp = gsap.utils.interpolate(filter, endValue)
    },
    render(progress, data) {
      data.target.style[blurProperty] = data.interp(progress)
    },
  })
})()

// below is an unofficial ClassNamePlugin for GSAP 3. You can copy/paste it into your project. Then, just add className: to your tweens. Relative values should work fine too (add and remove classes). Typically we recommend just explicitly animating specific properties to get better performance, but some people really appreciate having a plugin like this, especially for legacy projects. Enjoy!
// ClassNamePlugin START (requires GSAP 3.2.2 or later)
/* gsap.registerPlugin({
  name: "className",
  init: true,
  register(gsap, Plugin) {
    const CSSPlugin = gsap.plugins.css
    const cssCore = CSSPlugin.core || console.warn("Requires GSAP 3.2.1 or later") || {}
    const { _removeLinkedListItem } = gsap.core
    const { _removeProperty } = cssCore
    const { PropTween } = gsap.core
    const _getAllStyles = function (target, uncache) {
      const styles = {}
      const computed = getComputedStyle(target)
      let cache = target._gsap
      let p
      for (p in computed) {
        if (isNaN(p) && p !== "cssText" && p !== "length") {
          styles[p] = computed[p]
        }
      }
      uncache && cache && (cache.uncache = true)
      gsap.getProperty(target, "x")
      cache = target._gsap
      for (p in cache) {
        styles[p] = cache[p]
      }
      return styles
    }
    Plugin.prototype.init = function (target, endValue, tween) {
      const startClassList = target.getAttribute("class")
      const { style } = target
      const { cssText } = style
      const cache = target._gsap
      const { classPT } = cache
      const inlineToRemoveAtEnd = {}
      const end =
        endValue.charAt(1) !== "="
          ? endValue
          : startClassList.replace(new RegExp(`(?:\\s|^)${endValue.substr(2)}(?![\\w-])`), "") + (endValue.charAt(0) === "+" ? ` ${endValue.substr(2)}` : "")
      const plugin = this
      const changingVars = {}
      const startVars = _getAllStyles(target)
      const transformRelated = /(transform|perspective)/i
      const css = new CSSPlugin()
      const _renderClassName = function (ratio) {
        css.render(ratio, css)
        if (!ratio || ratio === 1) {
          target.setAttribute("class", ratio ? end : startClassList)
          for (const p in inlineToRemoveAtEnd) {
            _removeProperty(target, p)
          }
        }
      }
      let endVars
      let p
      if (classPT) {
        classPT.r(1, classPT.d)
        _removeLinkedListItem(classPT.d, classPT, "_pt")
      }
      target.setAttribute("class", end)
      endVars = _getAllStyles(target, true)
      target.setAttribute("class", startClassList)
      for (p in endVars) {
        if (endVars[p] !== startVars[p] && !transformRelated.test(p)) {
          changingVars[p] = endVars[p]
          if (!style[p] && style[p] !== "0") {
            inlineToRemoveAtEnd[p] = 1
          }
        }
      }
      cache.classPT = plugin._pt = new PropTween(null, target, "className", 0, 0, _renderClassName, plugin, 0, -11)
      if (style.cssText !== cssText) {
        style.cssText = cssText
      }
      cache.uncache = true
      gsap.getProperty(target, "x")
      css.init(target, changingVars, tween)
      plugin._props.push.apply(plugin._props, css._props)
      return 1
    }
  },
}) */

// add "active" class and yoyo back and forth. Open Dev Tools to watch the class attribute.
/* usage:
gsap.to(".test", {
  className: "+=active",
  ease: "power1.inOut",
  delay: 2,
  repeat: -1,
  yoyo: true,
  repeatDelay: 2,
  duration: 2
}); */
// ClassNamePlugin END


//wiggle function
export function wiggle(selector) {
  $(selector).each(function () {
    wiggleProp(this, 'scale', 0.93, 1);
    wiggleProp(this, 'rotation', -5, 5);
    wiggleProp(this, 'x', -3, 3);
    wiggleProp(this, 'y', -3, 3);
  })
}

function wiggleProp(element, prop, min, max) {

  var duration = Math.random() * (.1 - .2) + .1;

  var tweenProps = {
    ease: Power1.easeInOut,
    onComplete: wiggleProp,
    onCompleteParams: [element, prop, min, max]
  };
  tweenProps[prop] = Math.random() * (max - min) + min;

  TweenMax.to(element, duration, tweenProps);
}


