import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)


export function mobileScroll() {
  console.log("hey scrollStuffMobile.js here!");
  // scroll to top Button
  const scrollToTopBtn = document.getElementById("arrowup_mobile");

  function scrollToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }

  scrollToTopBtn.addEventListener("click", scrollToTop);
  // END scroll to top Button
  ScrollTrigger.refresh();
}

