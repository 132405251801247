export function round(num) {
  const m = Number((Math.abs(num) * 100).toPrecision(15))
  return (Math.round(m) / 100) * Math.sign(num)
}

const bg_img = ["./assets/7.webp", "./assets/5.webp", "./assets/4.webp"]

function pluckRandomFrom(array) {
  $("#id01").ripples("pause")
  if (!array.eligible || array.eligible.length === 0) {
    array.eligible = array.slice(0) // make a copy, attach it as "eligible" property
    array.eligible.sort(() => 0.5 - Math.random()) // shuffle
  }
  const new_bg = array.eligible.pop()
  console.log(new_bg)
  document.getElementById("id01").style.backgroundImage = new_bg
  $("#id01").ripples("set", "imageUrl", new_bg)
  gsap.set(".fun", { background: "url(" + new_bg + ")" })
}