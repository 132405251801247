import { getDeviceType } from "./importHelper"
import { mobileScroll } from "./scrollStuffMobile"
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

gsap.registerPlugin(ScrollTrigger)

let fixedElem = []

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);


const scrollToTopBtn = document.getElementById("arrowup")
const mycanvas = document.getElementById('mycanvas')
const contact_form = document.getElementById('contact_form')
document.location.pathname === "/projects.html" ?  fixedElem = gsap.utils.toArray(".overlay") : null
const scroller = document.querySelector('.scroller')
let bodyScrollBar


if (getDeviceType() === "Desktop" && scroller != null ) {
    desktopScroll()}
else {mobileScroll()}



function desktopScroll() {
  bodyScrollBar = Scrollbar.init(scroller, { damping: 0.05, delegateTo: document })
  console.log("hey locoscrollStuffDesktop.js here!")

  bodyScrollBar.track.xAxis.element.remove()
  bodyScrollBar.track.yAxis.element.remove()
  ScrollTrigger.scrollerProxy(".scroller", {
    scrollTop(value) {
      if (arguments.length) {
        bodyScrollBar.scrollTop = value
      }
      return bodyScrollBar.scrollTop
    },
  })

  bodyScrollBar.addListener(ScrollTrigger.update)

  ScrollTrigger.defaults({ scroller: scroller })

  bodyScrollBar.addListener(function (status) {
    let numFixedElements = fixedElem.length
    let offset = status.offset
    if (numFixedElements) {
      fixedElem.forEach((obj) => {
        fixedElem[numFixedElements - 1].style.top = `${offset.y}px`,
          fixedElem[numFixedElements - 1].style.left = `${offset.x}px`
      //  fixedElem[1].style.top = `${offset.y}px`
      //  fixedElem[1].style.left = `${offset.x}px`
    })
  }})
}

// scroll to top Button
function scrollToTop() {bodyScrollBar.scrollTo(0,0,1000)}
scrollToTopBtn.addEventListener("click", scrollToTop)

function enableScroll(){
  bodyScrollBar.updatePluginOptions('modal', { open: false })
  console.log("smooth-scrollbar scrolling enabled")
}
function disableScroll(){
  bodyScrollBar.updatePluginOptions('modal', { open: true })
  console.log("smooth-scrollbar scrolling disabled")
}


let smoothScrollBarContent = gsap.utils.toArray(".scroll-content")
console.log("ScrollBarContent: " + smoothScrollBarContent.length)

export {enableScroll, disableScroll, smoothScrollBarContent}

