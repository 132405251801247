import emailjs, { EmailJSResponseStatus } from "emailjs-com"
import { disablePageScroll, enablePageScroll } from "scroll-lock"
import gsap from "gsap"
import { TextPlugin } from "gsap/TextPlugin"
import "./gsap_effects_plugins"

gsap.registerPlugin(TextPlugin)
const close = document.querySelector('.close-container')
const form_container = document.getElementById("form_container")
const contact_form = document.getElementById("contactForm")
const tl_fadeIn = gsap.timeline({ paused: true })
const tl_fadeOut = gsap.timeline({ paused: true })
const tl_success = gsap.timeline({ paused: true })
const tl_marquee = gsap.timeline({ paused: true })
const tl_bgDark = gsap.timeline({ paused: true })
const tl_wiggle = gsap.timeline({ paused: true })

// prettier-ignore
function fadeIn() {
    tl_fadeIn
    .set(".services-ticker-block",{display:"flex"})
    /* .set("#contact_form", {pointerEvents:"none"}) */
    .fromTo("#form_container",0.5,{force3D:false, scale:0, rotation: 0.1,autoAlpha:0},{scale: 1, rotation: 0.0,autoAlpha:1, ease: "back.out(1)"},"<")
    /* .to(".line",0.3,{width:'100%',stagger:0.05,ease: "power2.Out"})  */
    /*  .set("#contact_form", {pointerEvents:"all"},0.5) */
    .fromTo(".line", 0.4, {width:"0%"}, {width:"100%", stagger:{each:0.02,from:"edges",ease:"ease-in-out"}},0.3)
    .fromTo(".text", 0.2, {y: "-101%"}, {y: "0%",ease:"ease-in-out2",stagger:0.05},0.4)
/*     .staggerFromTo('.text', 0.2, {y: '-101%'}, {y: '0%',  ease: "ease-in-out"}, 0.05,0.5) */
    .fromTo(".services-ticker-block", 0.2, {y: "-101%",autoAlpha:0}, {y: "0%",autoAlpha:1,stagger:0.05, ease: "power4.Out"},0.4)
    .set(".inputBox",{overflow:"inherit"})
    return tl_fadeIn;
/*     .fromTo('.services-ticker-block', 0.3, {y: '100%'}, {y: '0%',  ease: "try"},0.3) */
}

function bgDark() {
  tl_bgDark
  .set("#contact_form",{autoAlpha:1})
  .fromTo("#contact_form", 2.0,{backgroundColor:"rgba(19, 14, 17, 0.0)"},{backgroundColor:"rgba(19, 14, 17, 0.4)"})
  return tl_bgDark;
}


// prettier-ignore
function marquee() {
    tl_marquee
    .to(".marquee--long span",32,{x:"-200%",repeat:-1,ease: "none"},0.8)
    .to(".form_before .services-ticker-block .marquee span",30,{x:"-200%",repeat:-1,ease: "none"},0.8)
    return tl_marquee;
}

// prettier-ignore
function fadeOut() {
    tl_fadeOut
    .to(".services-ticker-block", 0.4, {y: "100%", ease: "back.in(1.7)"})
    /* .set(".marquee--long span, .form_before .services-ticker-block .marquee span",{x:"0%"}) */
    .set(".inputBox",{overflow:"hidden"})
    .to(".line", 0.2, {width:"0%", ease:"ease-in-out"},0.0)
    .to(".text", 0.3, {y: "280%",  ease: "back.in(1.7)"},0.2)
    .to("#form_container",0.5,{force3D:true,scale:0, rotation: 0.1,autoAlpha:0 ,ease: "back.in(1)"},0.7)
    //.to("#contact_form",0.01,{autoAlpha:0},1.6)
    .set(".text", {y: "-100%"},1.7)
    .set(".services-ticker-block",{display:"none"},1.7)
    return tl_fadeOut
}
// prettier-ignore
function newWiggle(){
    tl_wiggle
    .set(".sbmt", {cursor:"progress", pointerEvents:"none"})
    .to(".sbmt", {rotationX: 1800, z: -300, duration: 1.5,ease:"elastic.Out"})
    .to(".sbmt", {duration:1.2, background:"linear-gradient(45deg,#26d560 0%, #5d44e7cc 100%)",text:"SUCCESS!",scale:1.5},0.3)
    return tl_wiggle;
}

// prettier-ignore
function success(){
    tl_success
    .set(".sbmt", {cursor:"progress", pointerEvents:"none"})
    .to(".sbmt", {rotationX: 1800, z: -300, duration: 1.5,ease:"elastic.Out"})
    .to(".sbmt", {duration:1.2, background:"linear-gradient(45deg,#26d560 0%, #5d44e7cc 100%)",text:"SUCCESS!",scale:1.5},0.3)
    return tl_success;
}

const vfadeIn = fadeIn()
const vmarquee = marquee()
const vfadeOut = fadeOut()
const vsuccess = success()
const vbgDark = bgDark()

const hovContainer = gsap.utils.toArray(".form_before, .form_after")
// pause marquee on hover


if (localStorage.getItem("noAnimation") === null) {
  hovContainer.forEach(obj => {
    obj.addEventListener("mouseenter", () => gsap.to(vmarquee, { timeScale: 0, overwrite: true }))
  })
  hovContainer.forEach(obj => {
    obj.addEventListener("mouseleave", () => gsap.to(vmarquee, { timeScale: 0.55, overwrite: true }))
  })
}

const targets = gsap.utils.toArray(".git")
console.log(targets)


targets.forEach((obj) => {
  obj.addEventListener("click", formLogic)
})


function formLogic() {
  vfadeIn.reversed(!vfadeIn.reversed())
  if (tl_fadeIn.progress() == 0) {
    vbgDark.play()
    vfadeIn.timeScale(0.7).play()
    localStorage.getItem("noAnimation") === null ? vmarquee.timeScale(0.55).play() : vmarquee.kill(self)
  }
  if (tl_fadeIn.progress() > 0.6) {
    vfadeOut.timeScale(0.7).reverse()
    vmarquee.timeScale(0.0).play()
    vbgDark.reverse()
  }
}


function sendMail(event) {
  event.preventDefault()
  emailjs.init("user_N8q0mO4MxGxjOr013eiak")
  const tempParams = {
    from_name: document.getElementById("form-field-name").value,
    from_mail: document.getElementById("form-field-mail").value,
    message: document.getElementById("message_box").value,
    subject: document.getElementById("form-field-subject").value,
  }

  emailjs.send("contact_form", "template_qrwj42i", tempParams).then((res) => {
    console.log("success", res.status)
  })
  vsuccess.restart()

  setTimeout(function () {
    close.click()
    contact_form.reset()
  }, 3000)
}

const form = document.getElementById("contactForm")
form.addEventListener("submit", sendMail)
